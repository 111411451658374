import { environment } from 'src/environments/environment';

export const Constants = {
  dockWindowEnabled: true,
  applicationLogoUrl: 'assets/images/logo.svg',
  applicationLogoDarkUrl: 'assets/images/logo.svg',
  environmentsForErrorTracing: ['localhost', environment.hostUrl],
  splashScreenTimeout: 200,
  dateFormat: 'MM/dd/yyyy',
  pattern: {
    PHONE_NUMBER_PATTERN: new RegExp(/^(?:(?:\+|0{0,2})91[\s-]?)?(?:(?:\(\d{1,3}\))|\d{1,3})[\s-]?\d{4}[\s-]?\d{3,4}$/),
    PASSWORD_PATTERN: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
    FACEBOOK_URL: new RegExp('^\\S*(?:facebook\\.com|fb\\.me)\\S*$'),
    LINKED_IN_URL: new RegExp('^(https?://)?(www.)?linkedin.com/.*$'),
    EMAIL: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    WEBSITE_PATTERN: new RegExp('^(http[s]?:\\/\\/)?(www\\.)?[a-zA-Z0-9-.]+(\\.[a-zA-Z]{2,})+([\\/?].*)?$')
  },
  staticImages: {
    icons: {
      dashboardActive: 'assets/images/icons/Dashboard-Active.svg',
      dashboardHover: 'assets/images/icons/Dashboard-Hover.svg',
      lockPassword: 'assets/images/icons/Lock-Password.svg',
      loginUser: 'assets/images/icons/Login-User.svg',
      addNew: 'assets/images/icons/Add-New.svg',
      serviceIcon: 'assets/images/icons/service-icon.svg'
    },
    images: {
      profileImgPlaceholder: 'assets/images/profile-Image-placeholder.svg',
      pageNotFound: 'assets/images/page-not-found.svg',
      placeholderImage: 'assets/images/placeholder-image.jpg',
      category: {
        money: 'assets/images/icons/money.svg',
        minutes: 'assets/images/icons/Minutes.svg',
        mentoring: 'assets/images/icons/Mentoring.svg',
        movement: 'assets/images/icons/Movement.svg'
      },
      subCategories: {
        money: 'assets/images/icons/sub-category-money.svg',
        durationOfActivity: 'assets/images/icons/duration-of-activity.svg',
        goods: 'assets/images/icons/goods.png',
        moneyRaised: 'assets/images/icons/money-raised.svg',
        timeInTraining: 'assets/images/icons/time-in-traning.png',
        travel: 'assets/images/icons/travel.svg'
      }
    },
    loaders: {
      imageLoader: 'assets/loaders/skeleton-loader.gif',
      rotatingLoader: 'assets/loaders/image-loader.gif'
    }
  },
  successMessages: {
    changePassword: 'Password updated successfully',
    imageCompression: 'Image compression is complete successfully',
    deleteUser: 'User Deleted successfully',
    theme: 'Theme updated successfully',
    resetPassword: 'Your password has been reset successfully',
    resendInvite: 'Invitation sent successfully to ',
    userUpdated: 'User updated successfully',
    updatedSuccessfully: '{item} updated successfully.',
    passwordChange: 'Your password has been successfully changed',
    copyText: 'Copied Successfully',
    bannerUpdated: 'Banner updated successfully'
  },
  infoMessages: {
    imageCompression: 'No compression needed. Image size is within the limit.',
    imageType: 'Selected file must be an image',
    alreadyCreated: 'This service is already added'
  },
  errorMessages: {
    email: 'Email is undefined',
    samePasswords: 'The new password must not be the same as the current password',
    maxImage: 'Cannot upload more than 3 images',
    // TODO Remove once complete
    // maxSizeImage: 'We tried to compress the image, but it exceeds the maximum limit i.e. ',
    maxSizeImage: 'Image exceeds the maximum limit i.e. ',
    fillRequiredFields: 'Please fill required fields'
  },
  userRoles: [
    { label: 'Global Admin', value: 'GLOBAL_ADMIN' },
    { label: 'Event Organizer', value: 'EVENT_ORGANIZER' }
  ],
  userStatus: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ],
  bannerStatus: [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ],
  sortOptions: {
    ASC: 'ASC',
    DESC: 'DESC'
  },
  imgMaxSize: {
    label: '6MB',
    value: 6
  },
  twitterURL: 'https://twitter.com/',
  instagramURL: 'https://www.instagram.com/',
  events: {
    category: [
      {
        label: 'Money',
        value: 'MONEY'
      },
      {
        label: 'Mentoring',
        value: 'MENTORING'
      },
      {
        label: 'Minutes',
        value: 'MINUTES'
      },
      {
        label: 'Movement',
        value: 'MOVEMENT'
      }
    ],
    status: [
      {
        label: 'Pending Review',
        value: 'PENDING'
      },
      {
        label: 'Approved',
        value: 'APPROVED'
      },
      {
        label: 'Rejected',
        value: 'REJECTED'
      },
      {
        label: 'Cancel',
        value: 'CANCEL'
      }
    ],
    allOption: {
      label: 'All',
      value: null
    }
  },
  feedsFilter: {
    feedStatus: [
      {
        label: 'Pending',
        value: 'PENDING'
      },
      {
        label: 'Not Valid',
        value: 'NOT_VALID'
      },
      {
        label: 'Warning',
        value: 'WARNING'
      },
      {
        label: 'Deleted',
        value: 'DELETED'
      }
    ],
    severity: [
      {
        label: 'High',
        value: 'HIGH'
      },
      {
        label: 'Critical',
        value: 'CRITICAL'
      },
      {
        label: 'Normal',
        value: 'NORMAL'
      }
    ]
  }
};

export const LAYOUT_SCHEME = {
  isSidebarOnly: false,
  showFooter: false
};

export enum PROGRESS_TYPES {
  spinner = 'spinner',
  progressBar = 'progressBar'
}

export enum ROLES_ENUM {
  globalAdmin = 'GLOBAL_ADMIN',
  eventOrganizer = 'EVENT_ORGANIZER'
}
