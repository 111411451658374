import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuItemsCopy } from 'src/app/layout/authorized-layout/header/header.model';
import { ROLES_ENUM } from '../constants';
import { LocalStorageService, StorageItem } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {
  menuItems!: Array<MenuItem>;

  constructor(private readonly localStorageService: LocalStorageService) {}

  modifyMenuConfig(menuConfig: Array<MenuItem>) {
    const user: any = this.localStorageService.getItem(StorageItem.CurrentUser);
    this.menuItems = MenuItemsCopy;

    if (!user.role.includes(ROLES_ENUM.globalAdmin)) {
      menuConfig = menuConfig.filter(
        (menu) => menu.title !== 'User Management' && menu.title !== 'Feed Management' && menu.title !== 'Banners'
      );
    }
    this.menuItems = menuConfig;
  }

  getMenuConfigItems(): Array<MenuItem> {
    return this.menuItems;
  }
}
