import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { LocalStorageService, StorageItem } from '../services/local-storage.service';
import { NavigationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly localStorageService: LocalStorageService
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    const user: any = this.localStorageService.getItem(StorageItem.CurrentUser);
    let accessPage = false;
    for (const role of user?.role.split(',')) {
      if (activatedRouteSnapshot.data['roles']?.includes(role.trim())) {
        accessPage = true;
      }
    }

    if (accessPage) {
      return true;
    }

    this.navigationService.navigateToEvent();

    return false;
  }
}
