export const ROUTER_PATHS = {
  root: '/',
  auth: {
    root: 'auth',
    login: 'login',
    forgotPassword: {
      root: 'forgot-password',
      init: 'init',
      finish: 'finish'
    },
    password: 'password'
  },
  dashboard: {
    root: 'dashboard'
  },
  users: {
    root: 'users',
    list: 'list',
    create: 'create',
    edit: 'edit',
    view: 'view',
    createdByUsers: 'created-by-users',
    status: 'status',
    userId: ':id'
  },
  feedManagement: {
    root: 'feeds'
  },
  events: {
    root: 'events'
  },
  profile: {
    root: 'profile'
  },
  changePassword: {
    root: 'change-password'
  },
  error: {
    notFound: 'not-found'
  },
  crud: {
    edit: 'edit',
    view: 'view',
    id: ':id',
    create: 'create',
    organization: 'organization'
  },
  redirectToAuth: {
    root: 'redirect-to-authorized-page'
  },
  publicPages: {
    root: 'public',
    journey: 'journey'
  },
  banner: {
    root: 'banners',
    list: 'list',
    create: 'create',
    edit: 'edit',
    view: 'view',
    status: 'status',
    userId: ':id'
  }
};
