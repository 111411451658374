import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService, PrimeNGConfig } from 'primeng/api';

/**
 * This is to achieve abstraction,
 * If in future, we need to change toast service, we can change at this place.
 */

@Injectable({ providedIn: 'root' })
export class AppToasterService {
  toasterLife = 5000; // in milliseconds

  constructor(
    private readonly messageService: MessageService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly deviceService: DeviceDetectorService
  ) {
    this.primengConfig.ripple = true;
  }

  success(message?: string, title?: string, toasterLife?: number, icon?: string, styleClass?: string): void {
    this.messageService.add({
      severity: 'success',
      summary: title,
      detail: message,
      life: toasterLife ?? this.toasterLife,
      icon: icon,
      styleClass: styleClass,
      closable: !this.deviceService.isMobile()
    });
  }

  /** show error toast */
  error(message?: string, title?: string, toasterLife?: number, icon?: string, styleClass?: string): void {
    this.messageService.add({
      severity: 'error',
      summary: title,
      detail: message,
      icon: icon,
      life: toasterLife ?? this.toasterLife,
      styleClass: styleClass,
      closable: !this.deviceService.isMobile()
    });
  }

  /** show info toast */
  info(message?: string, title?: string, toasterLife?: number, icon?: string, styleClass?: string): void {
    this.messageService.add({
      severity: 'info',
      summary: title,
      detail: message,
      icon: icon,
      life: toasterLife ?? this.toasterLife,
      styleClass: styleClass,
      closable: !this.deviceService.isMobile()
    });
  }

  /** show warning toast */
  warning(message?: string, title?: string, toasterLife?: number, icon?: string, styleClass?: string): void {
    this.messageService.add({
      severity: 'warn',
      summary: title,
      detail: message,
      icon: icon,
      styleClass: styleClass,
      life: toasterLife ?? this.toasterLife,
      closable: !this.deviceService.isMobile()
    });
  }
}
