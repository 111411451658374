<p-dialog
  *ngIf="imageCropper.options.isCropperVisible"
  header="Header"
  [(visible)]="imageCropper.options.isCropperVisible"
  styleClass="dialog-dimensions"
  [closable]="false"
  [closeOnEscape]="true"
  [draggable]="false"
  [modal]="true">
  <ng-template pTemplate="header">
    <h6>
      Crop image
      <span class="sub-text" *ngIf="imageCropper" pTooltip="File Name">({{ imageCropper.options.name }})</span>
    </h6>
  </ng-template>
  <div class="cropper-container flex-md-row flex-column">
    <div class="cropper">
      <image-cropper
        *ngIf="imageCropper"
        [class.hidden]="isLoading"
        [imageChangedEvent]="imageCropper.options.imageChangedEvent"
        [maintainAspectRatio]="imageCropper.options.maintainAspectRatio"
        [aspectRatio]="imageCropper.options.aspectRatio"
        [resizeToHeight]="imageCropper.options.outputHeight"
        [resizeToWidth]="imageCropper.options.outputWidth ?? imageCropper.options.outputHeight"
        [roundCropper]="imageCropper.options.roundCropper"
        [containWithinAspectRatio]="imageCropper.options.containWithinAspectRatio"
        [onlyScaleDown]="true"
        [imageQuality]="100"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="isLoading = false"></image-cropper>
      <div *ngIf="imageCropper.options.showAspectRatio" class="aspect-ratio-container" [class.d-none]="isLoading">
        <ng-container *ngFor="let aspectRatio of aspectRatioOptions"
          ><p-button
            *ngIf="aspectRatio.value === imageCropper.options.aspectRatio"
            [label]="aspectRatio.label"
            (click)="changeAspectRatio(aspectRatio.value)"
            [styleClass]="'p-button-primary'"></p-button
        ></ng-container>
      </div>
      <ng-container *ngIf="imageCropper.options.showAspectRatioSelection">
        <div class="aspect-ratio-container" [class.d-none]="isLoading">
          <p-button
            *ngFor="let aspectRatio of aspectRatioOptions"
            [label]="aspectRatio.label"
            (click)="changeAspectRatio(aspectRatio.value)"
            [styleClass]="
              imageCropper.options.aspectRatio === aspectRatio.value ? 'p-button-primary' : 'p-button-outlined'
            "></p-button>
        </div>
        <h6 class="mt-1 text-center" [class.d-none]="isLoading">Select Aspect Ratio</h6>
      </ng-container>
    </div>
    <div *ngIf="!isLoading" class="preview-container">
      <img [src]="croppedImageUrl" alt="Preview" class="preview" [class.radius]="imageCropper.options.roundCropper" />
      <h6 class="preview-label">Preview</h6>
    </div>
  </div>
  <div *ngIf="isLoading" class="text-center">
    <img [src]="imageLoader" alt="Loading..." class="image-loader" />
  </div>
  <ng-template pTemplate="footer">
    <button
      pButton
      (click)="closeCropper()"
      class="p-button-outlined"
      routerLinkActive="router-link-active"
      pTooltip="Cancel"
      tooltipPosition="top">
      <i class="pi pi-times"></i><span class="ms-1">Cancel</span>
    </button>
    <button
      pButton
      icon="pi pi-check"
      (click)="saveCroppedImage()"
      label="Done"
      class="p-button"
      styleClass="p-button-text"
      appShowLoaderOnApiCall></button>
  </ng-template>
</p-dialog>
