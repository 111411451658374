import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncryptionService } from './encryption.service';

export enum StorageItem {
  AuthToken = 'authToken',
  RefreshToken = 'refreshToken',
  Theme = 'theme',
  FBPushNotificationObj = 'FBPushNotificationObj',
  CurrentUser = 'currentUser'
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(private readonly encryptionService: EncryptionService) {}
  skipEncryptionFor = [StorageItem.AuthToken, StorageItem.RefreshToken];

  getItem = (itemName: StorageItem): unknown | null => {
    const item: any = this.getStorage().getItem(itemName);
    if (this.skipEncryptionFor.includes(itemName)) {
      return item ? JSON.parse(item) : null;
    }
    const decryptValue = this.encryptionService.decrypt(item);
    return decryptValue ? JSON.parse(decryptValue) : null;
  };

  setItem = (itemName: StorageItem, value: any): void => {
    if (this.skipEncryptionFor.includes(itemName)) {
      this.getStorage().setItem(itemName, JSON.stringify(value));
      return;
    }
    const encryptValue = this.encryptionService.encrypt(value);
    this.getStorage().setItem(itemName, JSON.stringify(encryptValue));
  };

  removeItem = (itemName: StorageItem): void => {
    this.getStorage().removeItem(itemName);
  };

  getStorage = () => {
    return environment.persistUserSession ? localStorage : sessionStorage;
  };
}
