import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { UnauthorizedLayoutComponent } from './layout/unauthorized-layout/unauthorized-layout.component';
import { ROLES_ENUM, ROUTER_PATHS } from './shared/constants';
import { AuthGuard, NoAuthGuard } from './shared/guards';
import { PermissionGuard } from './shared/guards/permission.guard';

const routes: Routes = [
  {
    path: ROUTER_PATHS.auth.root,
    component: UnauthorizedLayoutComponent,
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
    canMatch: [() => inject(NoAuthGuard).canMatch()]
  },
  {
    path: ROUTER_PATHS.redirectToAuth.root,
    loadComponent: async () =>
      (await import('./redirect-to-authorized-page/redirect-to-authorized-page.component'))
        .RedirectToAuthorizedPageComponent
  },
  {
    path: `${ROUTER_PATHS.publicPages.root}/${ROUTER_PATHS.publicPages.journey}/${ROUTER_PATHS.crud.id}`,
    loadComponent: async () => (await import('./public-journey/public-journey.component')).PublicJourneyComponent,
    data: {
      pageTitle: 'Journey Details'
    }
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canMatch: [() => inject(AuthGuard).canMatch()],
    children: [
      {
        path: ROUTER_PATHS.dashboard.root,
        loadChildren: async () => (await import('./pages/dashboard/dashboard.module')).DashboardModule
      },
      {
        path: ROUTER_PATHS.users.root,
        loadChildren: async () => (await import('./pages/user-management/user-management.module')).UserManagementModule,
        canActivate: [PermissionGuard],
        data: {
          roles: [ROLES_ENUM.globalAdmin]
        }
      },
      {
        path: ROUTER_PATHS.feedManagement.root,
        loadChildren: async () =>
          (await import('./pages/feedback-management/feed-management.module')).FeedManagementModule,
        canActivate: [PermissionGuard],
        data: {
          roles: [ROLES_ENUM.globalAdmin]
        }
      },
      {
        path: ROUTER_PATHS.banner.root,
        loadChildren: async () => (await import('./pages/banners/banners.module')).BannersModule,
        canActivate: [PermissionGuard],
        data: {
          roles: [ROLES_ENUM.globalAdmin]
        }
      },
      {
        path: ROUTER_PATHS.events.root,
        loadChildren: async () =>
          (await import('./pages/event-management/event-management.module')).EventManagementModule
      },
      {
        path: ROUTER_PATHS.profile.root,
        loadChildren: async () => (await import('./pages/settings/user-profile/user-profile.module')).UserProfileModule
      },
      {
        path: `${ROUTER_PATHS.changePassword.root}`,
        loadComponent: async () =>
          (await import('./pages/settings/change-password/change-password.component')).ChangePasswordComponent,
        data: {
          pageTitle: 'Change Password'
        }
      },
      {
        path: `${ROUTER_PATHS.error.notFound}`,
        pathMatch: 'full',
        loadComponent: async () =>
          (await import('./pages/page-not-found/page-not-found.component')).PageNotFoundComponent,
        data: {
          pageTitle: 'Page Not Found'
        }
      },
      { path: '', redirectTo: ROUTER_PATHS.events.root, pathMatch: 'full' },
      {
        path: `**`,
        redirectTo: `${ROUTER_PATHS.error.notFound}`
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
