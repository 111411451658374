import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';
import { ROUTER_PATHS } from 'src/app/shared/constants';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { ServerMessage } from 'src/app/shared/models';
import { LocalStorageService, StorageItem } from 'src/app/shared/services/local-storage.service';
import { RefreshTokenService } from 'src/app/shared/services/refresh-token.service';
import { ForgotPasswordFinishRequestParams, ForgotPasswordInitRequestParams, LoginParams, LoginResponse, SetPasswordWhileAccountActivation } from '../models';
import { Account } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!this.localStorageService.getItem(StorageItem.AuthToken));
  currentUser!: Account | null;
  currentUserSubject: BehaviorSubject<Account | null> = new BehaviorSubject(this.currentUser);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly refreshTokenService: RefreshTokenService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {}

  login(loginParams: LoginParams): Observable<LoginResponse> {
    const headers = new HttpHeaders().set('source', 'WEB');
    return this.httpClient.post<LoginResponse>(API_URL.account.login, loginParams, { headers }).pipe(
      map((res) => {
        this.refreshTokenService.storeAuthTokens(res);
        this.refreshTokenService.startRefreshTokenTimer(res.access_token);
        this.isLoggedIn$.next(true);
        return res;
      }),
    );
  }

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  onForgotPasswordInit(params: ForgotPasswordInitRequestParams): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(`${API_URL.account.root}/${API_URL.account.forgotPassword.root}/${API_URL.account.forgotPassword.init}`, params);
  }

  onForgotPasswordFinish(params: ForgotPasswordFinishRequestParams): Observable<void> {
    return this.httpClient.post<void>(`${API_URL.account.root}/${API_URL.account.forgotPassword.root}/${API_URL.account.forgotPassword.finish}`, params);
  }

  onAccountActivation(key: string): Observable<ServerMessage> {
    return this.httpClient.get<ServerMessage>(`${API_URL.account.activate}?key=${key}`);
  }

  setPasswordOnAccountActivation(params: SetPasswordWhileAccountActivation): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(`${API_URL.account.root}/${API_URL.account.setPassword}`, params);
  }

  setCurrentUser$(value: Account) {
    this.currentUserSubject.next(value);
  }

  getCurrentUser$(): Observable<Account | null> {
    return this.currentUserSubject.asObservable();
  }

  getCurrentUser(fromDb = false): Observable<Account | null> {
    if (fromDb || !this.currentUser?.id) {
      const storedUser = this.localStorageService.getItem(StorageItem.CurrentUser);

      if (storedUser) {
        this.currentUser = storedUser as Account;
        this.currentUserSubject.next(storedUser as Account);
      }

      return this.httpClient.get<Account>(`${API_URL.account.root}/${API_URL.account.details}`).pipe(
        map((res) => {
          this.currentUser = res;
          this.currentUserSubject.next(res);
          this.localStorageService.setItem(StorageItem.CurrentUser, res);
          return res;
        }),
        catchError((err) => {
          this.logOut();
          return of(null);
        }),
      );
    }

    return of(this.currentUser);
  }

  sendNotification(notificationObj: any): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(`${API_URL.notification.root}/${API_URL.notification.broadcast}`, notificationObj);
  }

  logOut(): void {
    this.localStorageService.removeItem(StorageItem.AuthToken);
    this.localStorageService.removeItem(StorageItem.CurrentUser);
    this.currentUser = null;
    this.isLoggedIn$.next(false);
    this.refreshTokenService.stopRefreshTokenTimer();
    this.router.navigate([ROUTER_PATHS.auth.root]);
  }
}
