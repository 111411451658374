import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppLoaderService } from '../../services/app-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent implements OnInit {
  constructor(
    private readonly loaderService: AppLoaderService,
    private readonly cdr: ChangeDetectorRef
  ) {}
  loaderEnabled!: boolean;

  ngOnInit(): void {
    this.loaderService.loaderEnabled.asObservable().subscribe({
      next: (res) => {
        this.loaderEnabled = res;
        this.cdr.detectChanges();
      }
    });
  }
}
