<div class="loader-wrapper" *ngIf="loaderEnabled">
  <div class="backdrop fadein"></div>
  <div class="fadein">
    <p-progressSpinner
      styleClass="loader"
      strokeWidth="4"
      fill="var(--surface-ground)"
      animationDuration="2s"></p-progressSpinner>
  </div>
</div>
