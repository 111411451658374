import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Subject, takeUntil } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { faIcons } from '../constants/font-awesome-icons.constant';
import { CommonService } from '../services';

/**
 * @whatitdoes it injects the spinner on buttons based on API call status.
 * @howtouse <button appShowLoaderOnApiCall></div>
 */
@Directive({
  selector: '[appShowLoaderOnApiCall]'
})
export class ShowLoaderOnApiCallDirective implements OnInit, OnDestroy {
  visible = false;
  loadingSubscription: Subscription = new Subscription();
  destroy$: Subject<void> = new Subject();
  faIconElement!: ComponentRef<FaIconComponent>;

  constructor(
    private readonly elem: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
    private readonly commonService: CommonService
  ) {}

  ngOnInit() {
    this.commonService.isApiCallInProgress$.pipe(takeUntil(this.destroy$)).subscribe((isApiCallInProgress) => {
      if (!isApiCallInProgress && this.elem.nativeElement && this.faIconElement?.location?.nativeElement) {
        this.renderer.removeClass(this.elem.nativeElement, 'disabled');
        this.renderer.removeAttribute(this.elem.nativeElement, 'disabled');
        this.renderer.removeChild(this.elem.nativeElement, this.faIconElement?.location?.nativeElement);
        this.faIconElement.destroy();
        this.destroySubscription();
      }
    });
  }

  initializeSpinnerComponentElement() {
    const faIconElement = this.viewContainerRef.createComponent(FaIconComponent);
    faIconElement.instance.icon = faIcons.faSpinner;
    faIconElement.instance.animation = 'spin';
    this.renderer.addClass(faIconElement.location.nativeElement, 'btn-loader-icon');
    faIconElement.instance.render();
    this.faIconElement = faIconElement;
  }

  ngOnDestroy() {
    this.destroySubscription();
  }

  destroySubscription() {
    if (this.destroy$.observed) {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.destroy$ = new Subject();
    const parentFormElement = this.getParentFormElement(this.elem.nativeElement);
    if (parentFormElement) {
      if (!parentFormElement?.classList.contains('ng-invalid')) {
        this.applyLoaderOnClickedButton();
      }
    } else {
      this.applyLoaderOnClickedButton();
    }
  }

  private applyLoaderOnClickedButton(): void {
    this.commonService.isApiCallInProgress$.pipe(takeUntil(this.destroy$)).subscribe((isApiCallInProgress) => {
      if (isApiCallInProgress) {
        this.initializeSpinnerComponentElement();
        this.renderer.addClass(this.elem.nativeElement, 'disabled');
        setTimeout(() => {
          this.renderer.setAttribute(this.elem.nativeElement, 'disabled', 'true');
        }, 0);
        this.renderer.appendChild(this.elem.nativeElement.children[0], this.faIconElement?.location?.nativeElement);
      }
    });
  }

  private getParentFormElement(element: any): HTMLFormElement | undefined | void {
    const parentElement = element?.parentElement;
    if (parentElement instanceof HTMLFormElement) {
      return parentElement;
    } else {
      return this.getParentFormElement(parentElement);
    }
  }
}
