export class PaginationConfig {
  totalElements = 0;
  numberOfElements = 0;
  first = 0;
  maxSize = 8;
  predicate = 'id';
  sortOrder = 'ASC';
  pageNumber = 0;
  itemsPerPage = 10;
  sortField = undefined;
}
