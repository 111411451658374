import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LAYOUT_SCHEME } from 'src/app/shared/constants';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedLayoutComponent {
  layout = LAYOUT_SCHEME;
}
