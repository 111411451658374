import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER_PATHS } from '../constants';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private readonly router: Router) {}

  navigateToDashboard() {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.dashboard.root]);
  }

  navigateToUser() {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.users.root]);
  }

  navigateToEvent() {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.events.root]);
  }

  navigateToRedirectToAuthorizedPage() {
    this.router.navigate([ROUTER_PATHS.redirectToAuth.root]);
  }

  navigate(commands: [], navigationExtras?: { [key: string]: any } | undefined): Promise<boolean> {
    return this.router.navigate(commands, navigationExtras);
  }

  navigateToBanners() {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.banner.root]);
  }
}
