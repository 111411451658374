<app-header *ngIf="!layout.isSidebarOnly"></app-header>
<app-sidebar *ngIf="layout.isSidebarOnly"></app-sidebar>
<div class="content-wrapper">
  <div class="content container logged-in flex-1 flex-child">
    <div class="mt-3">
      <router-outlet></router-outlet>
      <p-scrollTop
        target="parent"
        [styleClass]="layout.showFooter ? 'scrolltop-position' : ''"
        [threshold]="100"
        icon="pi pi-arrow-up"></p-scrollTop>
    </div>
  </div>
</div>
<app-footer *ngIf="layout.showFooter"></app-footer>
