<img
  [src]="currentUser?.imageURL?.fullUrl ?? ''"
  [appImageLoader]="constants.staticImages.loaders.imageLoader"
  [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
  alt="Profile image"
  class="profile-img pointer"
  (click)="isMobile ? toggleDock() : accountOptions.toggle($event)" />

<p-overlayPanel appendTo="body" styleClass="account-info-panel" #accountOptions>
  <ng-template pTemplate="content">
    <div class="profile-details-container" *ngIf="profileMenuType">
      <div>
        <img
          [src]="currentUser?.imageURL?.fullUrl ?? ''"
          [appImageLoader]="constants.staticImages.loaders.imageLoader"
          [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
          alt="Profile image"
          class="profile-img" />
      </div>
      <div class="ms-2">
        <div class="name">
          {{ currentUser?.firstName + " " + (currentUser?.lastName ?? "") }}
        </div>
        <div class="email">{{ currentUser?.email }}</div>
      </div>
    </div>
    <div
      *ngFor="let option of allAccountOptions"
      [ngClass]="['options-container', option.class]"
      (click)="accountOptions.toggle($event)"
      [routerLink]="[option.route]"
      [routerLinkActive]="'active-option'"
      appItemHover>
      <i [class]="option.icon"></i>
      <span class="ms-2">{{ option.title }}</span>
    </div>
    <div class="options-container">
      <p-divider></p-divider>
    </div>

    <div class="options-container text-danger logout-hover" (click)="confirmLogout()">
      <i class="pi pi-sign-out text-danger"></i>
      <span class="ms-2">Logout</span>
    </div>
  </ng-template>
</p-overlayPanel>
<div class="dock-window" *ngIf="dockWindowEnabled && isMobile && showDock">
  <p-dock [model]="items" [position]="position"> </p-dock>
</div>
<p-confirmDialog></p-confirmDialog>
