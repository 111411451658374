import { ROUTER_PATHS } from 'src/app/shared/constants';

export const MenuItems = [
  {
    id: ROUTER_PATHS.users.root,
    title: 'User Management',
    icon: '',
    routerLink: ROUTER_PATHS.users.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  },
  {
    id: ROUTER_PATHS.events.root,
    title: 'Events',
    icon: '',
    routerLink: ROUTER_PATHS.events.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  },
  {
    id: ROUTER_PATHS.feedManagement.root,
    title: 'Feed Management',
    icon: '',
    routerLink: ROUTER_PATHS.feedManagement.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  },
  {
    id: ROUTER_PATHS.banner.root,
    title: 'Banners',
    icon: '',
    routerLink: ROUTER_PATHS.banner.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  }
];

export const MenuItemsCopy = [
  {
    id: ROUTER_PATHS.events.root,
    title: 'Events',
    icon: '',
    routerLink: ROUTER_PATHS.events.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  },
  {
    id: ROUTER_PATHS.feedManagement.root,
    title: 'Feed Management',
    icon: '',
    routerLink: ROUTER_PATHS.feedManagement.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  },
  {
    id: ROUTER_PATHS.users.root,
    title: 'User Management',
    icon: '',
    routerLink: ROUTER_PATHS.users.root,
    isAccessible: true,
    parentRoute: null,
    subMenuOptions: [] as any
  }
];

export enum ToggleDirections {
  LEFT = 'left',
  RIGHT = 'right'
}
