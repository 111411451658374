import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { MenuItems, ToggleDirections } from '../header/header.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends BaseComponent implements OnInit {
  @Input() toggleDirection: ToggleDirections = ToggleDirections.LEFT;
  @Input() showSidebar = false;
  @Input() currentActiveParentRoute!: string;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(false);
  menuItems = MenuItems;
  toggleDirections = ToggleDirections;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService
  ) {
    super();
    if (this.layout.isSidebarOnly) {
      this.setActiveRoute();
      this.getCurrentUser();
    }
  }

  ngOnInit(): void {}

  onSidebarClosed(): void {
    if (this.layout.isSidebarOnly) {
      this.showSidebar = false;
    } else {
      this.onClose.emit(false);
    }
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }

  getCurrentUser(): void {
    if (!this.authService.currentUser) {
      this.authService
        .getCurrentUser()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          this.currentUser = res;
          if (this.currentUser) {
            this.authService.setCurrentUser$(this.currentUser);
          }
          this.cdr.detectChanges();
        });
    }
  }
}
